import React from 'react'
import { getMediaData, queryVideoData } from "../lib/API"
import { useData } from "../lib/hooks"
import { VideoThumbnail } from "./Video"
import moment from 'moment'
import pic02 from '../assets/images/pic02.jpg'
import Link from './Link'


export default ( { className, id } ) => {
    const videos = useData( getMediaData, 'featured-videos' )
    const { video_1, video_2, video_3 } = videos
    const topVideos = video_1 && [ video_1, video_2, video_3 ]

    return(
        <section id="video-highlight" className="main style2 special">
            <div className="grid-wrapper">
                <div className="col-12">
                    <header className="major">
                        <h2>Featured Sermons</h2>
                    </header>
                </div>

                {topVideos && topVideos.map( ( { title, url, description, date, id } ) =>  
                (
                    <div className="col-4" key={id} data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000' >
                        <Link id={`video${id}`} to={`/media/videos/${id}`} className="video-link">
                            {/* <h3>{moment( date ).format( "DD MMM YYYY" ).toUpperCase()}</h3> */}
                            <span className="image fit"><VideoThumbnail url={url} className={className} /></span>
                            <h4>{title}</h4>
                            <p>{description}</p>
                        </Link>
                        
                    </div>
                
                
                ) )}
                <div className="col-12">
                    <ul className="actions">
                        <li data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000'><Link to="/media/videos" className="button">All Sermons</Link></li>
                    </ul>
                </div>

            </div>
        </section>
    )
}