import React, {useContext} from "react"
import Helmet from "react-helmet"

import Layout from '../components/layout'
import Header from '../components/Header'
import Section, { TextSection, BackgroundSection, Button } from '../components/Section'


import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'

import { getMediaHeader, getStaticPageData } from "../lib/API"
import { useData, useLocalData } from "../lib/hooks"
import Link from "../components/Link"

import BannerSection from "../components/BannerSection"
import VideoHighlight from "../components/VideoHighlight"

// import LiveSermonImage from '../assets/images/home/live-camera.jpg'
// import LiveSermonImage from '../assets/images/general/8.jpg'
import LiveSermonImage from "../assets/images/church-interior/10.jpg"


// import AudioImage from '../assets/images/home/audio.jpg'
import AudioImage from '../assets/images/core/Front5_Cropped.jpg'
import BannerContext from "../lib/context"



const MediaPage = () => {
    const siteTitle = "Blackheath and Charlton Baptist Church"

    const pageName = 'media-banners'
    // const pageData = useLocalData( getStaticPageData, pageName, pageName )
    const pageData = useContext( BannerContext )[pageName]
    const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = header ? header.images : [] 
    const headerTitle = header ? header.title : 'Services & Media'
    
    // const headerImages = useData( getMediaHeader )
    // const headerTitle = "Services and Media"
    const headerIcon = <span className="icon major"><i className="fas fa-film"></i></span>
    const headerButtons = [
        { url: "/media#one", text: "View Sermons" }
    ]

    return (
            
        <div id='media-page'>
            <Helmet title={`${siteTitle} - Services and Media`} />
            <Header images={headerImages} headerTitle={headerTitle} tint={0.1} headerButtons={headerButtons} />

            <section id="services" className="main style1 special">
                <div className="grid-wrapper">
                    {/* <div className="col-12">
                        <header className="major">
                            <h1>Services</h1>
                        </header>
                    </div> */}
                    <div className="col-6">
                        <div className="major">
                            <h2>Sunday Morning - 10:30am</h2>
                            <p>Sunday Morning Service Description</p>
                            {/* <h2>10:30am - 12:00pm</h2> */}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="major">
                            <h2>Friday Evening - 7:00pm</h2>
                            <p>Friday Evening Service Description</p>
                            {/* <h3>06:00pm - 7:30pm</h3> */}
                        </div>
                    </div>
                        {/* <p>You are welcome to join us in person or alternatively join us online for our livestreaming service</p> */}
                    {/* <div className="col-12 live-service-section">
                        <ul className="actions uniform">
                            <Button url='https://www.youtube.com/channel/UCo1-ajfKW62orxAK4TPkQ3Q/live' special={true} text='Watch Live'/> 
                        </ul>
                    </div> */}
                </div>
            </section>

            <TextSection 
                id="live-sermons" 
                title="Online Service"
                style="style1"
                background={[ LiveSermonImage ]}
                description="Access our livestreamed services here"
                buttons={[ { url: 'https://www.youtube.com/channel/UCo1-ajfKW62orxAK4TPkQ3Q/live', external: true, text: 'Watch Now', style: 'transparent-button' } ]}
                tint={0.0}
                blur={0.1}

            />

            <VideoHighlight />   

            {/* <BackgroundSection 
                id="audio-section" 
                title="Audio Sermons"
                className='style2'
                description={`Find all our audio sermons here, where you can listen and be enriched by the word of God`}
                background={[ AudioImage ]}
                buttons={[ { url: '/media/audio', special: false, text: 'Listen',  style: 'transparent-button' } ]}
                tint={0.55}
                blur={0.05}
                alt

            /> */}

            <TextSection 
                id="audio-section" 
                title="Prayer Meeting Audio"
                className='style2'
                description={`Find all our prayer meeting audios here, where you can listen and be enriched by the word of God`}
                background={[ AudioImage ]}
                buttons={[ { url: '/media/audio', special: false, text: 'Listen',  style: 'transparent-button' } ]}
                tint={0.55}
                blur={0.05}
                alt

            />

            <BannerSection banners={banners} />
            
        </div>
    )
}

export default MediaPage